<template>
  <div>
    <span>订单明细</span>
  </div>
</template>

<script>
export default {
  name: "operate"
}
</script>

<style scoped>

</style>
